<template>
  <div class="trunk">
    <div class="lb">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <div class="f-bottom">
            <img src="https://img.hbcygc.net/website/v3/mobile/homes/carousel1.png">
            <div class="f-panel">
              <div class="font-panel">
                <p class="p1">{{ $t('content.home.lb[1]') }}</p>
                <p class="p2">{{ $t('content.home.lb[2]') }}</p>
                <p class="p3 plb1">{{ $t('content.home.lb[0]') }}</p>
                <router-link to="/crosser" target="_blank" class="alb1">
                  <span>{{ $t('content.home.lb[4]') }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="f-bottom">
            <img src="https://img.hbcygc.net/website/v3/mobile/homes/carousel2.png">
            <div class="f-panel">
              <div class="font-panel">
                <p class="p1">{{ $t('content.home.lb[1]') }}</p>
                <p class="p2">{{ $t('content.home.lb[2]') }}</p>
                <p class="p3 plb2">{{ $t('content.home.lb[0]') }}</p>
                <router-link to="/crosser" target="_blank" class="alb2">
                  <span>{{ $t('content.home.lb[4]') }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination home-sp" slot="pagination"></div>
      </swiper>
    </div>
    <div class="hxys">
      <div class="hxys-top">
        <div>{{ $t('content.home.hxys.title') }}</div>
        <div class="btns">
          <a href="javascript:;" @click="hxys_prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
          </a>
          <a href="javascript:;" @click="hxys_next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </a>
        </div>
      </div>
      <div class="hxys-list">
        <div class="hxys-panel">
          <swiper :options="swiperOption2" ref="mySwiper">
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys1.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[0].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[0].info') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys2.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[1].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[1].info') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v3/pc/homes/hxys3.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[2].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[2].info') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys4.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[3].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[3].info') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys5.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[4].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[4].info') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="hxys-item-panel">
                <img src="https://img.hbcygc.net/website/v2/homes/hxys6.png">
                <div class="font-panel">
                  <div class="p1">{{ $t('content.home.hxys.list[5].title') }}</div>
                  <div class="p2">{{ $t('content.home.hxys.list[5].info') }}</div>
                </div>
              </div>
            </swiper-slide>
   
          </swiper>
        </div>
      </div>
    </div>
    <div class="video-panel">
      <video ref="video" class="video-js vjs-default-skin" poster="https://img.hbcygc.net/website/v3/mobile/homes/newvideofm.jpg" playsinline controls>
        <source src="https://img.hbcygc.net/website/v3/mobile/homes/newvideo.mp4" />
      </video>
    </div>
    <div class="download-panel">
      <img src="https://img.hbcygc.net/website/mobilev2/homes/scancode.png">
      <div class="item-panel">
        <div class="download-item">
          <div class="d-title">{{ $t('content.home.download.title') }}</div>
          <div class="qrcodes">
            <div class="child-panel">
              <img :src="AndroidUrl.img">
              <div class="d-text">Android</div>
            </div>
            <div class="child-panel">
              <img :src="IosUrl.img">
              <div class="d-text">IOS</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.swiper-container-list2 {
  overflow: visible;
}
.home-sp{
.swiper-pagination-bullet {
  width: 35px;
  height: 4px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
}

</style>
<style scoped lang="less">
.lb {
  position: relative;
  z-index: 0;
  min-height: 100vh;

  .f-bottom {
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
    }

    .f-panel {
      width: 100%;
      height: 100%;
      // background-color: salmon;
      // opacity: 0.5;
      position: absolute;
      z-index: 3;
      left: 0;
      top: 0;

      .font-panel {
        position: relative;
        z-index: 4;
        // background-color: sandybrown;
        text-align: center;
        margin-top: 20%;

        .p1 {
          font-family: 'Righteous-Regular';
          font-size: 48px;
          margin-bottom: 0;
        }

        .p2 {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .p3 {
          font-size: 18px;
          margin-bottom: 20px;
        }

        .plb1 {
          color: #8E8E8E;
        }

        .plb2 {
          color: #D2D7DF;
        }

        .alb1 {
          border: 1px solid #8E8E8E;
        }

        .alb2 {
          border: 1px solid #fff;
        }

        a {
          text-decoration: none;
          color: #fff;
          padding: 5px 15px 5px 18px;
          border-radius: 100px;
          white-space: nowrap;
          font-size: 14px;

          svg {
            width: 14px;
            height: 14px;
            margin-left: 5px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
.hxys {
  width: 100%;
  height: auto;
  background-color: #fff;
  position: relative;
  z-index: 0;

  .hxys-top {
    font-size: 30px;
    // background-color: aquamarine;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px 15px;
    color: #000000;

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 15px;

      a {
        color: #9E9E9E;
        background-color: #F5F5F5;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 0.5s;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      a:hover {
        color: #fff;
        background-color: #000000;
      }
    }
  }

  .hxys-list {
    width: 100%;
    height: auto;
    // background-color: blueviolet;
    position: relative;
    overflow-x: hidden;

    .hxys-panel {
      width: 90vw;
      margin: 0 auto;

      .hxys-item-panel {
        width: 100%;
        height: auto;
        background-color: #F4F6F9;
        overflow: hidden;
        position: relative;
        z-index: 0;

        img {
          width: 100%;
          height: auto;
          transition: 1s;
          position: relative;
          z-index: 1;
        }

        img:hover {
          transform: scale(1.2);
        }

        .font-panel {
          position: absolute;
          z-index: 2;
          left: 25px;
          bottom: 15px;
          color: #fff;
          // border: 1px solid red;

          .p1 {
            font-size: 22px;
            margin-bottom: 10px;
          }

          .p2 {
            font-size: 12px;
            margin-bottom: 10px;
            color: #AFAFAF;
          }
        }
      }
    }
  }
}
.video-panel {
  background-color: #000000;
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 0;

  .video-js {
    width: 90vw;
    height: 60vw;
  }
}
.download-panel {
  position: relative;
  z-index: 0;

  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .item-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    .download-item {
      text-align: center;
      .d-title {
        font-size: 25px;
        margin-bottom: 20px;
      }

      .qrcodes {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 20px;

        .child-panel {
          img {
            width: 120px;
            height: auto;
          }

          .d-text {
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }


}
</style>
<script>

import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { getQrCode } from '../utils/api';

export default {
  name: 'HomeSwiper',
  components: {
    swiper,
    swiperSlide
  },

  data() {
    return {
      swiperOption: {
        loop: true,//可选选项，开启循环
        autoplay: true,//可选选项，自动滑动
        pagination: {
          el: '.swiper-pagination',
          // bulletActiveClass: 'my-bullet-active',
        },
        paginationType: 'fraction',
        paginationClickable: true,
      },
      swiperOption2: {
        loop: true,//可选选项，开启循环
        autoplay: true,//可选选项，自动滑动
        paginationType: 'fraction',
        paginationClickable: true,
      },
      player: null, // 用来存储当前 video
      videoOpenInti: false,
      AndroidUrl: {
        img: require('../assets/images/notimg.png'),
        url: ''
      },
      IosUrl: {
        img: require('../assets/images/notimg.png'),
        url: ''
      },
    };
  },
  mounted() {
    this.player = this.$video(this.$refs.video,{
            muted: true,
            autoplay: true
        });
    // window.addEventListener("scroll", (e) => {
    //   if ($('.video-js').length > 0 && $('.video-js').get(0).getBoundingClientRect().top < 300) {
    //     this.openVideo();
    //   }
    // });
  },
  created() {
    this.getCode();
  },
  methods: {
    hxys_prev() {
      console.log(this.$refs.mySwiper.swiper.slidePrev());
    },
    hxys_next() {
      console.log(this.$refs.mySwiper.swiper.slideNext());
    },
    // openVideo() {
    //   if (!this.videoOpenInti) {
    //     this.videoOpenInti = true;
    //     this.player.play();
    //   }
    // },
    getCode() {
      let _self = this;
      let areas = localStorage.getItem("lang");
      getQrCode({searchkey:areas}).then(
        response => {
          if (response.code == 200) {
            let data = response.data;
            _self.AndroidUrl.img = data.android.imgbase;
            _self.IosUrl.img = data.ios.imgbase;
          }
        }
      ).catch(error => {
        console.log('error:' + error);
      })
    }
  }
}
</script>