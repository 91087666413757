<template>
  <div id="app">
    <nav>
      <div class="menu-top">
        <router-link to="/">
          <img id="logo" src="https://img.hbcygc.net/website/v2/companys/logo2.png">
        </router-link>
        <a href="javascript:;" v-show="!isShowMenu" @click="btn_show_menu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
        </a>
        <a href="javascript:;" v-show="isShowMenu" @click="btn_close_menu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </a>
      </div>
      <div class="menu-panel" v-show="isShowMenu">
        <router-link class="n-row" to="/crosser" target="_blank">
          <span>{{ $t('content.menu.crosser') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
        <router-link class="n-row" to="/screen" target="_blank">
          <span>{{ $t('content.menu.screen') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
        <router-link class="n-row" to="/future" target="_blank">
          <span>{{ $t('content.menu.future') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
        <router-link class="n-row" to="/fos" target="_blank">
          <span>futureOS</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
        <router-link class="n-row" to="/company" target="_blank">
          <span>{{ $t('content.menu.company') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
           <router-link class="n-row" to="/instructions" target="_blank">
          <span>{{ $t('content.menu.instructions') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
        <router-link class="n-row" to="/info" target="_blank">
          <span>{{ $t('content.menu.account') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </router-link>
      </div>
    </nav>
    <router-view />
    <footer>
      <div class="menu">
        <p>{{ $t('content.menu.zj') }}</p>
        <router-link to="/crosser" target="_blank">{{ $t('content.menu.crosser') }}</router-link>
            <router-link to="/screen" target="_blank">{{ $t('content.menu.screen') }}</router-link>
            <router-link to="/future" target="_blank">{{ $t('content.menu.future') }}</router-link>
        <p>{{ $t('content.menu.qpt') }}</p>
        <router-link to="/fos" target="_blank">{{ $t('content.menu.fos') }}</router-link>
            <router-link to="/ar" target="_blank">{{ $t('content.menu.ar') }}</router-link>
            <router-link to="/creator" target="_blank">{{ $t('content.menu.creator') }}</router-link>
        <p>{{ $t('content.menu.gywm') }}</p>
        <router-link to="/meta" target="_blank">{{ $t('content.menu.meta') }}</router-link>
            <router-link to="/company" target="_blank">{{ $t('content.menu.company') }}</router-link>
            <router-link to="/about" target="_blank">{{ $t('content.menu.about') }}</router-link>
            <router-link to="/consult" target="_blank">{{ $t('content.menu.consult') }}</router-link>
        <p>{{ $t('content.menu.language') }}</p>
        <a href="javascript:;" :style="{ textDecoration: this.language == 'zh' ? 'revert' : 'none' }"
          @click="btn_language('zh')">简体中文</a>
        <a href="javascript:;" :style="{ textDecoration: this.language == 'en' ? 'revert' : 'none' }"
          @click="btn_language('en')">English</a>
      </div>
      <div class="about">
        <img src="./assets/images/future.png" />
        <p class="p1">{{ $t('content.menu.title') }}</p>
        <p class="p2">{{ $t('content.menu.email') }}</p>
        <p class="p3">service@fxreye.com</p>
      </div>
      <div class="location">
        <p>{{ $t('content.menu.address') }}</p>
        <p>
          Copyright 2020-2023 Future Fullscreen Intelligent Technology Group Co., Ltd.
          鄂ICP备鄂ICP备2023002274号-1
          <img src="./assets/images/police-badge.png">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=42018502007119" rel="noreferrer"
            target="_blank">鄂公网安备42018502007119</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "Righteous-Regular";
  src: url('./font-familys/Righteous-Regular.ttf')
}

@font-face {
  font-family: "pangmenzhengdao";
  src: url('./font-familys/pangmenzhengdao.ttf')
}

@font-face {
  font-family: "douyuzhuiguang";
  src: url('./font-familys/douyuzhuiguang.ttf')
}

/* 宽度 */
::-webkit-scrollbar {
  width: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

:root {
  font-size: 16px;
}

body {
  position: relative;
  z-index: -20;

  .ms-mobile-select {
    position: fixed
  }
}


#app {
  position: relative;
  z-index: -10;
}

.trunk {
  background-color: #000000;
  min-height: 100vh;
  color: #fff;
  height: auto;
  position: relative;
  z-index: -1;

  p {
    margin: 0;
  }

  .trends-font {
    transform: translate(0px, 100px);
    transition: 1.5s;
  }
}

.trunk-top {
  margin-top: 50px;
}

.trunk-white {
  background-color: #fff;
  color: #000000;
}



nav {
  width: 100%;
  color: #191919;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;

  .menu-top {
    width: 100%;
    height: 50px;
    // background-color: blueviolet;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    img {
      width: auto;
      height: 25px;
    }

    svg {
      width: 25px;
      height: 25px;
    }

    a {
      text-decoration: none;
    }

    a:link {
      color: #191919;
    }

    a:active {
      color: #191919;
    }

    a:visited {
      color: #191919;
    }
  }

  .menu-panel {
    // background-color: seagreen;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;

    .n-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EFEFEF;
      width: 94vw;
      margin: auto;
      padding: 10px 0;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    div:last-child {
      border-bottom: 0;
    }


    a {
      color: #191919;
      text-decoration: none;

      // &.router-link-exact-active {
      //   color: red;
      // }
    }
  }
}

footer {
  width: 100%;
  height: auto;
  background-color: #141414;
  padding: 20px 30px;
  position: relative;
  z-index: 90;

  .menu {
    margin-bottom: 50px;

    p {
      color: #fff;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    a {
      color: #75787B;
      text-decoration: none;
      display: block;
      // border: 1px solid red;
      margin-bottom: 10px;
    }

    a:link {
      color: #75787B;
    }

    a:active {
      color: #75787B;
    }

    a:visited {
      color: #75787B;
    }
  }

  .about {
    margin-bottom: 30px;

    img {
      width: auto;
      height: 10px;
      margin-bottom: 5px;
    }

    p {
      color: #75787B;
    }

    .p1 {
      margin-bottom: 30px;
      font-weight: bold;
    }

    .p2 {
      margin-bottom: 0;
      font-weight: bold;
    }

    .p3 {
      margin-bottom: 0;
      font-family: "pangmenzhengdao";
      font-size: 15px;
    }
  }

  .location {
    color: #404040;
    font-size: 12px;

    img {
      width: 12px;
      height: auto;
      margin: 0 5px;
    }

    a {
      color: #404040;
      text-decoration: none;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      isShowMenu: false,
      language: localStorage.getItem("lang") || "zh"
    }
  },
  created() {
    if (this.$isMobile()) {
      // window.location.href='http://106.14.199.94:8068/';
    } else {
      window.location.href = 'https://www.future.top/';
    }
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (to.name != from.name) {
          this.btn_close_menu();
        }
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.btn_close_menu);
  },
  beforeDestroy() {
    window.removeEventListener("scroll");
    //window.removeEventListener('scroll', this.btn_close_menu);
  },
  methods: {
    btn_show_menu() {
      this.isShowMenu = true;
    },
    btn_close_menu() {
      this.isShowMenu = false;
    },
    btn_language: function (type) {
      switch (type) {
        case 'en':
          localStorage.setItem("locale", "en");
          this.$i18n.locale = localStorage.getItem("locale");
          localStorage.setItem("lang", "en");
          // alert('切换到英文');
          break;
        case 'zh':
          localStorage.setItem("locale", "zh");
          this.$i18n.locale = localStorage.getItem("locale");
          localStorage.setItem("lang", "zh");
          // alert('切换到中文');
          break;
        default:
          break;
      }
      location.reload();
    },
  }
}
</script>
